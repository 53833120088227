export enum Category {
    BASIC,
    LOCATION,
    CONSUMPTION,
    WALL,
    FLOOR,
    WINDOW,
    ROOF,
    TECHNOLOGY,
    BASICIMAGES,
    WINDOWIMAGES
}